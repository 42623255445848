<template>
  <transition name="fade" appear>
    <div>
      <section class="headNavInfo">
        <div class="headNavInfo_title">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home' }">学练考</el-breadcrumb-item>
            <el-breadcrumb-item>练习</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </section>
      <ComContent type="exercise" />
    </div>
  </transition>
</template> 

<script>
import ComContent from '@/views/practice/components/com-content'
export default {
  components: {
    ComContent
  }

}
</script>

<style>
</style>